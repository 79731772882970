import Glide, { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'

new Glide('.glide', {
  type: 'carousel',
  startAt: 0,
  perView: 1
}).mount({ Controls, Breakpoints });

const glideControls = document.getElementsByClassName('glide-controls')[0].getElementsByTagName('span');
const landingSection = document.querySelector('section.home.landing');
const nav = document.getElementsByTagName('nav')[0];

glideControls[0].addEventListener('click', (e) => {
  landingSection.classList.remove('about-us');
  nav.classList.remove('about-us');
});

glideControls[1].addEventListener('click', (e) => {
  landingSection.classList.add('about-us');
  nav.classList.add('about-us');
});